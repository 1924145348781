/* Table of Content
==================================================
1.    Slider
2.    Call to action
3.    Features
4.    Facts
5.    Services
6.   Project area
7.   Content area
8.   Testimonial
9.   Subscribe area
10.   News section
11.   Footer
12.   Sub Pages
13.   Contact Us
14.   News Listing
15.   News Single
16.   Sidebar
17.   Error page
*/

/* Slider
================================================== */
/*-- Main slide --*/
.banner-carousel .banner-carousel-item {
  height: 700px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  @include desktop {
    height: 550px;
  }
  @include mobile {
    height: 450px;
  }
}

.slider-content {
  position: relative;
  height: 100%;
  width: 100%;
}

.slide-title-box {
  font-size: 16px;
  line-height: 39px;
  background: $color-primary;
  color: #fff;
  display: inline-block;
  padding: 0 15px;
  margin: 0 0 10px;
}

.slide-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: #fff;
  margin: 20px 0 10px;
  @include desktop {
    font-size: 22px;
  }
  @include mobile {
    font-size: 16px;
  }
}

.slide-sub-title {
  font-style: normal;
  font-size: 60px;
  line-height: 58px;
  margin: 20px 0;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -1px;
  @include desktop {
    font-size: 46px;
  }
  @include mobile {
    font-size: 30px;
    line-height: 30px;
  }
}

.slider-text {
  display: table;
  vertical-align: bottom;
  color: #fff;
  padding-left: 40%;
  width: 100%;
  padding-bottom: 0;
  padding-top: 20px;
}

.slider-text .slide-head {
  font-size: 36px;
  color: #0052a5;
  position: relative;
}

.slider-img {
  display: block;
  position: absolute;
  top: -80px;
  left: 20%;
  width: 314px;
  max-height: 100%;
}

.slider-img img {
  display: block;
  position: relative;
  max-height: 100%;
  width: auto;
}

.slider.btn {
  margin: 15px 5px 0;
  border: 2px solid transparent;
  @include mobile {
    font-size: 12px;
  }
}

.slider.border {
  background: none;
  border: 2px solid $color-primary !important;
}

.slider.border:hover {
  background: $color-primary;
  border: 2px solid transparent;
}


/* Carousel control */
.banner-carousel .carousel-control {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  transform: translateY(-50%);
  background-color: transparent;
  opacity: 0;
  filter: alpha(opacity=0);
  text-shadow: none;
  transition: all .25s ease;
  padding: 0;
  outline: 0;
  border: 0;
  @include mobile {
    display: none !important;
  }
}

.banner-carousel .slick-dots {
  opacity: 0;
  filter: alpha(opacity=0);
  bottom: 60px;
}
.slick-dots {
  li button::before {
    font-size: 12px;
  }
  li button:hover:before, .slick-dots li button:focus:before,
  li.slick-active button:before {
    opacity: 1;
    color: $color-primary;
  }
}
.slick-slide {
  outline: 0;
}

.banner-carousel:hover .carousel-control,
.banner-carousel:hover .carousel-control,
.banner-carousel:hover .slick-dots {
  opacity: 1;
  filter: alpha(opacity=100);
}

.banner-carousel .carousel-control.left {
  left: 20px;
}

.banner-carousel .carousel-control.right {
  right: 20px;
}

.banner-carousel .carousel-control i {
  background: rgba(0, 0, 0, .3);
  color: #fff;
  line-height: 58px;
  width: 60px;
  height: 60px;
  font-size: 22px;
  border-radius: 0;
  transition: all 500ms ease;
}

.banner-carousel .carousel-control i:hover {
  background: $color-primary;
  color: #fff;
}


/*-- Animation */
.banner-carousel [data-animation-in] {
  opacity: 0;
}


/* slick Box slider */
.box-slider-content {
  top: 50%;
  padding: 0;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  @include tablet {
    top: auto;
    transform: translateY(0);
    left: 20px;
    bottom: 20px;
    width: calc(100% - 40px);
  }
}

.box-slider-text {
  background: #111;
  background: rgba(0, 0, 0, 0.65);
  display: inline-block;
  padding: 20px 30px;
  max-width: 650px;
}

.box-slide-title {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: #fff;
  @include tablet {
    font-size: 16px;
  }
}

.box-slide-sub-title {
  font-size: 36px;
  margin: 8px 0 10px;
  color: #fff;
  @include tablet {
    font-size: 26px;
  }
}

.box-slide-description {
  color: #fff;
  @include tablet {
    font-size: 15px;
  }
}

.box-slider-text .btn {
  padding: 10px 20px;
}

.box-slide.owl-theme .owl-controls {
  margin: -20px;
}


/* Call to action
================================================== */

.call-to-action {
  background-color: #272d33;
  padding: 30px;
}

.call-to-action-title {
  color: #fff;
  margin: 0;
  padding: 25px 0;
  line-height: normal;
  font-size: 22px;
  text-transform: capitalize;
}


/* Action style box */

.call-to-action-box {
  margin-top: -50px;
}

.call-to-action-box .action-style-box {
  background: $color-primary;
  padding: 30px;
}

.action-title {
  color: #fff;
  margin: 0;
  line-height: 36px;
  font-size: 18px;
  text-transform: uppercase;
}


/* Intro
================================================== */


/* Intro */

.ts-intro {
  padding-right: 20px;
}

.into-title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 18px;
  line-height: normal;
  margin: 0;
}

.into-sub-title {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 32px;
  line-height: normal;
  margin: 10px 0;
}



/*-- Featured Tab --*/

.featured-tab {
  padding-left: 15px;
}

.featured-tab .nav-tabs {
  border: 0;
}

.featured-tab .nav-tabs>li>a {
  font-family: 'Roboto Slab', serif;
  background: #272d33;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  border-radius: 0;
  text-transform: uppercase;
  line-height: 50px;
  margin: 0 1px 20px;
  padding: 0 20px;
  border: 0 !important;
  transition: all 300ms ease;
}

.featured-tab .tab-content {
  border-top: 0;
  padding: 0;
  overflow: hidden;
}

.featured-tab .nav.nav-tabs {
  margin-bottom: 10px;
}

.featured-tab .nav-tabs>li.active>a {
  color: #fff;
  background: $color-primary;
  position: relative;
}

.featured-tab .nav-tabs>li.active>a:after {
  position: absolute;
  content: " ";
  width: auto;
  height: auto;
  bottom: -20px;
  left: 50%;
  border-width: 10px;
  border-style: solid;
  border-color: $color-primary rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  transform: translateX(-50%);
}

.featured-tab .lead {
  font-size: 18px;
  line-height: 28px;
  color: #303030;
  font-weight: 400;
}

.featured-tab .tab-pane img.pull-left {
  margin: 0 30px 10px 0;
}

.featured-tab .tab-pane img.pull-right {
  margin: 0 0 0 30px;
}

.featured-tab .tab-icon i {
  font-size: 230px;
}

.tab-image-content {
  padding-left: 0;
}

.featured-tab .border-title.border-left {
  display: inline-block;
  margin-bottom: 25px;
  font-size: 20px;
}


/* Tab services */

.featured-tab.tab-services .nav-tabs>li>a {
  font-size: 14px;
  padding: 0 21px;
  text-transform: capitalize;
}

.featured-tab.tab-services .nav.nav-tabs {
  margin-bottom: 20px;
}


/* Facts
================================================== */


.facts-wrapper {
  text-align: center;
}

.facts-wrapper .ts-facts {
  color: #fff;
}

.ts-facts .ts-facts-icon i {
  font-size: 42px;
  color: $color-primary;
}

.ts-facts .ts-facts-content .ts-facts-num {
  color: #fff;
  font-size: 44px;
  margin: 30px 0 20px;
}

.ts-facts .ts-facts-content .ts-facts-title {
  font-size: 16px;
  color: $color-primary;
  margin: 0;
}


/* Services
================================================== */

/* Service box */

.ts-service-box .ts-service-icon i {
  font-size: 36px;
  float: left;
  color: $color-primary;
}

.ts-service-box .ts-service-box-content {
  margin-left: 62px;
  margin-bottom: 30px;
}

.tab-content .ts-service-box .ts-service-box-content {
  margin-left: 90px;
}

.ts-service-box .ts-service-box-content h3 {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 28px;
}

.ts-service-box .ts-service-box-info {
  margin-left: 25px;
  margin-bottom: 40px;
}

.service-box-title {
  font-size: 16px;
  margin: 0 0 10px;
  a {
    color: #333;
    &:hover {
        color: $color-primary;
    }
  }
}

/* Service box bg */
.ts-service-box-bg {
  background: #252525;
  color: #fff;
  padding: 30px;
}

.ts-service-box-bg h4,
.ts-service-box-bg h3 {
  color: #fff;
}


/* Service box 

.ts-service-box .ts-service-icon i {
  font-size: 24px;
  float: left;
  color: #fff;
  background: $color-primary;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.ts-service-box .ts-service-box-content {
  margin-left: 80px;
  margin-bottom: 30px;
}

.tab-content .ts-service-box .ts-service-box-content {
  margin-left: 90px;
}

.ts-service-box .ts-service-box-content h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.ts-service-box .ts-service-box-info {
  margin-left: 90px;
  margin-bottom: 40px;
}

.service-box-title {
  font-size: 18px;
  margin: 0 0 10px;
}

.service-box-title a {
  color: #333;
}

.service-box-title a:hover {
  color: $color-primary;
}

.service-center-img {
  padding: 0 20px;
}
*/

/* Icon squre */
.ts-service-icon.icon-round i {
  font-size: 24px;
  color: #fff;
  background: $color-primary;
  text-align: center;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
  position: relative;
  float: none;
}


/* Icon left */

.ts-service-box.icon-left .ts-service-box-icon {
  float: left;
}

.ts-service-box.icon-left .ts-service-box-icon i {
  background: $color-primary;
  color: #fff;
}

.ts-service-box.icon-left .ts-service-box-info {
  margin-left: 90px;
}

.ts-service-box.icon-left .ts-service-box-info h3 {
  margin-top: 0;
  margin-bottom: 5px;
}


/* Service no box */

.service-no {
  font-size: 48px;
  color: #dbdbdb;
  float: left;
  margin-top: 10px;
}

.ts-service-box-content .ts-service-box-info {
  margin-left: 90px;
}


/* Service Image */
.ts-service-image-wrapper {
  margin-bottom: 30px;
}

.ts-service-icon i {
  font-size: 28px;
  margin-right: 15px;
  margin-top: 2px;
}

.ts-service-info {
  margin-left: 30px;
  h3 {
    font-size: 16px;
  }
  .learn-more {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    &:hover {
        color: $color-primary;
    }
  }
}


/* Service Classic */

.ts-service-classic .ts-service-icon i {
  font-size: 24px;
  float: left;
  color: #fff;
  background: $color-primary;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  transition: all 0.3s;
}

.ts-service-classic .ts-service-box-info {
  margin-left: 80px;
}

.ts-service-classic:hover .ts-service-icon i {
  background: $color-primary;
}


/* Projects area
================================================== */


/* Project filter nav */
.shuffle-btn-group {
  display: inline-block;
  margin: 20px 0 50px;
  width: 100%;
  border-bottom: 3px solid $color-primary;
  label {
    display: inline-block;
    color: #212121;
    font-size: 14px;
    padding: 6px 25px;
    padding-top: 10px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s;
    cursor: pointer;
    margin: 0;
    &.active {
        color: #212121;
        background: $color-primary;
    }

    input {
        display: none;
    }
  }
}


/* Project shuffle Item */
.shuffle-item {
  padding: 0;

  .project-img-container {
    position: relative;
    overflow: hidden;
    img {
        transform: perspective(1px) scale3d(1.1, 1.1, 1);
        transition: all 400ms;
    }
    &:hover img {
        transform: perspective(1px) scale3d(1.15, 1.15, 1);
    }
    &:after {
        opacity: 0;
        position: absolute;
        content: '';
        top: 0;
        right: auto;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        transition: all 400ms;
    }
    &:hover:after {
        opacity: 1;
    }

    .gallery-popup .gallery-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding: 5px 12px;
        background: $color-primary;
        color: #fff;
        opacity: 0;
        transform: perspective(1px) scale3d(0, 0, 0);
        transition: all 400ms;
    }
    &:hover .gallery-popup .gallery-icon {
        opacity: 1;
        transform: perspective(1px) scale3d(1, 1, 1);
    }

    .project-item-info {
        position: absolute;
        top: 50%;
        margin-top: -10%;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 30px;
        z-index: 1;
    }
    .project-item-info-content {
        opacity: 0;
        transform: perspective(1px) translate3d(0, 15px, 0);
        transition: all 400ms;

        .project-item-title {
          font-size: 20px;
          a {
              color: #fff;
              &:hover {
                color: $color-primary;
              }
          }
        }
        .project-cat {
          background: $color-primary;
          display: inline-block;
          padding: 2px 8px;
          font-weight: 700;
          color: #000;
          font-size: 10px;
          text-transform: uppercase;
        }
    }
    &:hover .project-item-info-content {
        opacity: 1;
        transform: perspective(1px) translate3d(0, 0, 0);
    }
  }
}

.general-btn .btn-primary:hover {
  background: #000;
}


/* Project owl */

.owl-theme.project-slide {
  margin-top: 60px;
}

.project-slide .item {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.project-item img {
  width: 100%;
  max-width: auto;
  transition: all 0.6s ease 0s;
}

.project-item:hover img {
  transform: scale(1.1, 1.1);
}

.project-item-content {
  position: absolute;
  bottom: -1px;
  padding: 15px 20px;
  width: 100%;
  background: $color-primary;
}

.owl-theme.project-slide .owl-nav>div {
  position: absolute;
  top: -70px;
  right: 0;
  transform: translateY(-50%);
}

.project-slide .owl-nav>div {
  display: inline-block;
  margin: 0 2px;
  font-size: 20px;
  background: $color-primary;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.owl-theme.project-slide .owl-nav>.owl-prev {
  right: 35px;
  left: auto;
}


/* Projects Single page */

.project-title {
  font-size: 30px;
}

.project-info-label {
  color: #303030;
  font-weight: 700;
  font-size: 14px;
}

.project-info-content {
  font-size: 12px;
}

.project-info li {
  margin-bottom: 5px;
}

.project-link {
  margin-top: 15px;
}


/* Content area
================================================== */


/* Accordion */
.accordion-group {
  .card {
    border-radius: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf !important;
  }
  .card-body {
    padding: 15px 20px;
    img {
        max-width: 100px;
        margin-bottom: 10px;
    }
  }
  .card-header .btn {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 13px 15px;
    padding-bottom: 10px;

    &:before {
        font-family: "Font Awesome 5 Free";
        position: absolute;
        z-index: 0;
        font-size: 14px;
        right: 16px;
        padding: 3px 8px 1px;
        text-align: center;
        border-radius: 3px;
        top: 12px;
        content: "\f107";
        font-weight: 700;
        background-color: #333;
        color: #fff;
        transition: .3s;
    }

    &[aria-expanded="true"] {
      color: $color-primary;
      &::before {
        content: "\f106";
        background-color: $color-primary;
      }
    }
  }
  &.accordion-classic {
    .card-header .btn:before {
      display: none;
    }
  }
}


/* Clients */
.clients-logo {
  margin: 0 0 30px;
  border: 1px solid #dadada;
  min-height: 105px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.clients-logo img {
  filter: grayscale(100%);
  filter: gray;
}

/* Testimonial
================================================== */
.testimonial-area {
  padding: 100px 0;
  background-color: $color-primary;
  background-image: url(../images/parallax2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 50%;
}

.testimonial-area .border-title,
.testimonial-area .border-sub-title {
  color: #fff;
}

.quote-item .quote-text {
  margin: 0 0 10px;
  display: inline-block;
  padding: 0 40px 30px 60px;
  background-color: #fff;
  position: relative;
  font-size: 16px;
  font-style: italic;
  @include mobile {
    padding: 0 40px 30px 50px;
  }
}

.quote-item .quote-text:before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 0;
  font-weight: 700;
  color: #ffc009;
}

.testimonial-slide .slick-dots {
  bottom: -35px;
}


img.testimonial-thumb {
  max-width: 80px;
  float: left;
  margin-right: 20px;
  border-radius: 5px;
}

.quote-item-info {
  padding-top: 15px;
  display: inline-block;
}

.quote-author {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 2px;
  font-weight: 700;
  display: block;
  color: $color-primary;
}


.quote-item-footer {
  margin-top: 0;
  margin-left: 60px;
  @include mobile {
    margin-left: 0;
  }
}


/* Testimonial Border */

.quote-item.quote-border .quote-text-border {
  border: 1px solid $color-primary;
  padding: 20px;
  position: relative;
  line-height: 28px;
  color: #666;
  font-size: 18px;
  text-align: center;
}

.quote-item.quote-border .quote-text-border:before {
  border: 12px solid;
  border-color: #e1e1e1 transparent transparent;
  border-top-color: $color-primary;
  bottom: -24px;
  position: absolute;
  content: "";
  display: block;
  height: 0;
  width: 0;
  left: 0;
  margin: 0 auto;
  right: 0;
}

.quote-item.quote-border .quote-text-border:after {
  border: 12px solid;
  border-color: #fff transparent transparent;
  bottom: -22px;
  position: absolute;
  content: "";
  display: block;
  height: 0;
  width: 0;
  left: 0;
  margin: 0 auto;
  right: 0;
}

.quote-item.quote-border .quote-item-footer {
  margin-left: 0;
  text-align: center;
  margin-top: 35px;
}

.quote-item.quote-border .quote-item-footer img.testimonial-thumb {
  float: none;
  margin: 0;
}

.quote-item.quote-border .quote-item-footer .quote-item-info {
  display: block;
}


/* Subscribe area
================================================== */

.subscribe {
  background: $color-primary;
  padding: 0;
}


/* Call to action */

.subscribe-call-to-acton {
  min-height: 115px;
  padding: 30px 0 0 30px;
  @include mobile {
    text-align: center;
    padding: 30px 0;
    min-height: auto;
  }
}

.subscribe-call-to-acton h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 5px;
}

.subscribe-call-to-acton h4 {
  color: #fff;
  font-size: 24px;
  margin: 0;
}


/* Newsletter */

.ts-newsletter {
  background: #252525;
  position: relative;
  min-height: 115px;
  padding: 15px 0 0 50px;
  @include tablet {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  @include mobile {
    padding: 30px 5px 15px 5px;
    min-height: auto;
    text-align: center;
  }
  &:after {
    content: "";
    position: absolute;
    background: #252525;
    display: block;
    width: 100%;
    height: 100%;
    right: -100%;
    top: 0;
  }
}
.newsletter-form input {
  background: none;
  font-size: 12px;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, .3);
}


/* News section
================================================== */

.latest-post {
  position: relative;
}

.latest-post .image-angle:before {
  border-bottom: 20px solid #f9f9f9;
}

.latest-post .post-title {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: 600;
}

.latest-post .post-title a {
  color: #303030;
}

.latest-post .post-title a:hover {
  color: $color-primary;
}

.latest-post-meta {
  font-size: 13px;
  text-transform: uppercase;
}

.latest-post .post-body {
  padding: 20px 0 5px;
}


/* Footer
================================================== */


/*- Footer common */

.footer {
  background-color: #0d0d0d;
  color: #999;
}


.footer-main {
  padding: 80px 0 60px;
}

.footer .widget-title {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  margin: 0 0 30px;
  padding-left: 15px;
  text-transform: uppercase;
  color: #fff;
  border-left: 3px solid $color-primary;
}




/* Footer about us */

.footer-logo {
  margin-bottom: 25px;
  max-height: 35px;
}


/* Footer social */

.footer-social {
  margin-top: 15px;
}

.footer-social .widget-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.footer-social ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -13px;
  li {
    display: inline-block;
    a i {
      display: block;
      font-size: 16px;
      color: #999;
      transition: 400ms;
      padding: 10px 13px;
    }
    &:hover {
      color: #fff;
    }
  }
}




/* Links */

.footer-widget ul.list-arrow li {
  border-bottom: 1px solid rgba(255, 255, 255, .07);
  padding: 8px 0;
}

.footer-widget ul.list-arrow li:last-child {
  border-bottom: 0;
}

.footer-widget ul.list-arrow li a,
.footer-widget ul.list-arrow li:before {
  color: #747474;
}

.footer-widget ul.list-arrow li:hover a,
.footer-widget ul.list-arrow li:hover {
  color: $color-primary;
}

.working-hours {
  padding-right: 10px;
}

.working-hours .text-right {
  float: right;
}


/*-- Copyright --*/

.copyright {
  background: $color-primary;
  color: #111;
  padding: 25px 0;
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 12px;
}

.footer-menu ul li {
  display: inline-block;
  line-height: 12px;
  padding-left: 15px;
}

.footer-menu ul.nav li a {
  background: none;
  color: #111;
  padding: 0;
}

.footer-menu ul li a:hover {
  color: #fff;
}

#back-to-top {
  right: 40px;
  top: auto;
  z-index: 10;
  display: none;
  .btn:focus {
    outline: 0;
    box-shadow: none
  }
  @include tablet {
    right: 15px;
  }
}

#back-to-top.position-fixed {
  bottom: 20px;
}

#back-to-top .btn.btn-primary {
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  color: $color-primary;
  font-weight: 700;
  font-size: 16px;
  padding: 0;
  @include tablet {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
}

#back-to-top .btn.btn-primary:hover {
  color: #fff;
}


/* Sub Pages
================================================== */


/*-- Title border --*/

.main-container .border-left {
  margin-bottom: 30px;
}


/*-- Banner --*/

.banner-area {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
}

.banner-title {
  color: #fff;
  text-transform: uppercase;
  font-size: 58px;
  font-weight: 900;
  @include tablet {
    font-size: 48px;
  }
  @include mobile {
    font-size: 32px;
  }
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  transform: translateY(-50%);
}

.banner-heading {
  text-align: center;
}

.breadcrumb {
  padding: 0;
  background: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  li a,
  .breadcrumb-item,
  li a:focus,
  li a:hover {
    color: #fff !important;
  }
  .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
  }
}


/*-- About us page --*/


/* Slider pages */
.page-slider {
  .carousel-control {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    transform: translateY(-50%);
    background-color: transparent;
    text-shadow: none;
    transition: all .25s ease;
    padding: 0;
    outline: 0;
    border: 0;
  }

  .carousel-control.left {
    left: 0;
  }
  .carousel-control.right {
    right: 0;
  }
  .carousel-control i {
    background: rgba(0, 0, 0, .3);
    color: #fff;
    line-height: 70px;
    width: 40px;
    height: 70px;
    font-size: 22px;
    border-radius: 0;
    transition: all 500ms ease;
  }
  .carousel-control i:hover {
    background: $color-primary;
    color: #fff;
  }
}
.page-slider.small-bg .item {
  min-height: 330px;
  background-size: cover;
}

.page-slider.small-bg .box-slider-content {
  left: 20%;
}

.page-slider.small-bg .box-slider-text {
  background: rgba(0, 0, 0, 0.55);
  padding: 5px 20px;
}

.page-slider.small-bg .box-slide-title {
  font-size: 28px;
  color: #fff;
  font-weight: 900;
}


/*-- Get a quote page --*/

.page-quote-form {
  background: #f2f2f2;
  padding: 30px 50px 50px
}

.get-a-quote-img {
  margin-bottom: -190px;
}


/*-- Partners carousel --*/

.partner-logo {
  border: 1px solid #eee;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.partner-logo img {
  transition: 350ms;
  opacity: .6;
  filter: grayscale(100%);
}

.partner-logo:hover img {
  opacity: 1;
  filter: grayscale(0%);
}

#partners-carousel .partner-logo {
  border: 0;
  border-right: 1px solid #eee;
  padding: 0 15px;
  margin-top: 20px;
}

#partners-carousel .partner-logo.last {
  border-right: 0;
}


/*-- Team page --*/
.team-slide {
  .slick-slide {
    margin-left: 25px;
    padding-bottom: 60px;
  }
  .slick-list {
    margin-left: -25px;
  }
  .carousel-control {
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 0;
    background-color: #ddd;
    height: 37px;
    width: 37px;
    border-radius: 4px;
    transition: .3s;
    z-index: 6;
    &:hover {
        background-color: $color-primary;
        color: #fff;
    }
    &.left {
        transform: translateX(calc(-50% - 25px));
    }
    &.right {
        transform: translateX(calc(-50% + 25px));
    }
    &.slick-disabled {
        opacity: .4;
        &:hover {
          background-color: #ddd;
          color: #000;
        }
    }
  }
}
.ts-team-wrapper {
  position: relative;
  overflow: hidden;
  .ts-team-content {
    position: absolute;
    top: 76%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 25px;
    margin-top: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    transition: 350ms;
    @include desktop-lg {
        top: 72%;
    }
    @include mobile {
        text-align: center;
    }
  }
  .ts-name {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 3px;
    line-height: normal;
    color: $color-primary;
  }
  .ts-designation {
    color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 700;
  }
  .team-social-icons {
    a i {
        color: #fff;
        margin-right: 8px;
        text-align: center;
        transition: 400ms;
        &:hover {
          color: $color-primary;
        }
    }
  }
  &:hover .ts-team-content {
    top: 0;
    padding-top: 50px;
  }
}


/* Team Classic */
.ts-team-content-classic {
  margin-top: 15px;
  .ts-name {
    font-size: 16px;
    margin-bottom: 5px;
    color: #212121;
  }
  .ts-designation {
    color: #888;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .team-social-icons a i {
    color: #999;
  }
  .team-social-icons a i:hover {
    color: $color-primary;
  }
}


/*-- Pricing table  --*/
.ts-pricing-box {
  margin: 20px 0;
  padding: 0;
  text-align: center;
  background: #f9f9f9;
  .ts-pricing-header {
    background: #252525;
    color: #fff;
    position: relative;
    padding: 30px 20px;
  }
  .ts-pricing-name {
    font-size: 18px;
    line-height: normal;
    margin: 0 0 5px 0;
    color: #fff;
  }
  .ts-pricing-price {
    font-size: 44px;
    color: #fff;
    margin: 15px 0 0;
    display: inline-block;
    > small {
      font-size: 16px;
      line-height: 16px;
      display: block;
      margin-top: 15px;
      color: #fff;
    }
  }
  .ts-pricing-features {
    padding: 15px 0;
    ul {
      padding: 0 20px;
      >li {
        padding: 20px 0;
        border-top: 1px dotted #e5e5e5;
        &:first-child {
          border-top: 0;
        }
      }
    }
  }
  .plan-action {
    padding-bottom: 40px;
  }
}
/* Pricing featured */
.ts-pricing-box.ts-pricing-featured .ts-pricing-header {
  background: adjust-hue($color-primary, -7);
}



/* Contact Us
================================================== */

.map {
  height: 450px;
  z-index: 1;
}

.contact-info-box {
  margin-top: 20px;
}

.contact-info-box i {
  float: left;
  font-size: 24px;
  color: $color-primary;
}

.contact-info-box-content {
  padding-left: 40px;
}

.contact-info-box-content h4 {
  font-size: 16px;
  margin-top: 0;
  line-height: normal;
  font-weight: 700;
}

.contact-info-box-content p {
  margin-bottom: 0;
}

label {
  font-weight: 400;
}


/* Contact page 2 */

.ts-service-box.text-center .ts-service-icon.icon-squre i {
  float: none;
  margin-bottom: 20px;
}

.ts-service-box.text-center .ts-service-box-content {
  margin: 0;
}


/* News Listing
================================================== */

.post {
  border-bottom: 1px solid #dadada;
  padding: 0 0 30px;
  margin: 0 0 45px;
}

.post.last {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.post-body {
  padding: 20px 0;
}

.entry-header .entry-title {
  font-size: 24px;
  margin: 5px 0 15px;
  position: relative;
  line-height: 34px;
  text-transform: capitalize;
}

.entry-header .entry-title a {
  color: #303030;
}

.entry-header .entry-title a:hover {
  color: $color-primary;
}

.post-single .entry-header .entry-title {
  font-size: 28px;
}


/* Meta */

.post-meta {
  padding-bottom: 10px;
}

.post-meta a {
  color: #303030;
}

.post-meta a:hover {
  color: $color-primary;
}

.post-meta span {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #dadada;
  line-height: 12px;
  display: inline-block;
}

.post-meta i {
  color: #bbb;
  margin-right: 3px;
}

.post-meta .post-comment {
  border-right: 0;
}

.post-meta .post-comment .comments-link {
  margin-left: 5px;
}

.post-footer .btn.btn-primary {
  font-size: 12px;
  margin-top: 10px;
}


/* Pagination */

.paging {
  margin-bottom: -5px;
}

.pagination li a {
  border-radius: 0 !important;
  margin-right: 8px;
  color: #7c7c7c;
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>li>a:hover {
  color: #fff;
  background: $color-primary;
  border: 1px solid transparent;
}


/* News Single
================================================== */

.tags-area {
  margin: 20px 0;
}

.post-tags a {
  border: 1px solid #dadada;
  color: #7c7c7c;
  display: inline-block;
  font-size: 12px;
  padding: 3px 10px;
  margin-left: 3px;
}

.post-tags a:hover {
  background: $color-primary;
  color: #fff;
  border: 1px solid transparent;
}


/* Post social */

.post-social-icons>li {
  display: inline-block;
}

.post-social-icons a i {
  margin-left: 5px;
  font-size: 12px;
  width: 28px;
  height: 26px;
  line-height: 26px;
  color: #fff;
  text-align: center;
}

.post-social-icons a i.fa-facebook-f {
  background: #41578a;
}

.post-social-icons a i.fa-twitter {
  background: #64bae2;
}

.post-social-icons a i.fa-google-plus {
  background: #c0343d;
}

.post-social-icons a i.fa-linkedin {
  background: #3397b6;
}


/* Author box */

.author-box {
  border: 1px solid #dadada;
  padding: 20px 20px 15px;
  margin: 20px 0;
}

.author-img img {
  width: 110px;
  height: 110px;
  margin-right: 30px;
}

.author-info h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}

.author-info h3 span {
  font-size: 12px;
  color: #999;
  border-left: 1px solid #AFAFAF;
  padding-left: 10px;
  margin-left: 10px;
  font-weight: 500;
}


/* Comments area */

.comments-area {
  margin: 40px 0;
}

.comments-list .comment-content {
  margin: 15px 0;
}

.comments-list .comment-reply {
  color: #303030;
  font-weight: 400;
}

.comments-list .comment-reply:hover {
  color: $color-primary;
}

.comments-counter {
  font-size: 18px;
}

.comments-counter a {
  color: #323232;
}

.comments-list {
  list-style: none;
  margin: 0;
  padding: 20px 0;
}

.comments-list .comment {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.comments-list .comment.last {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.comments-list img.comment-avatar {
  width: 84px;
  height: 84px;
  margin-right: 30px;
  @include mobile {
    width: 40px;
    height: 40px;
    margin-right: 25px;
  }
}

.comments-list .comment-author {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
  font-size: 16px;
  color: #303030;
}

.comments-list .comment-date {
  color: #959595;
  margin-bottom: 5px;
  font-size: 12px;
  @include mobile {
    float: none !important;
    display: inline-block;
  }
}

.comments-reply {
  list-style: none;
  margin: 0 0 0 70px;
  @include tablet {
    margin: 0;
    padding: 0;
  }
}

.comments-form {
  margin-bottom: 0;
}

.comments-form .title-normal {
  margin-bottom: 20px;
}

.comments-form .btn.btn-primary {
  margin-top: 20px;
}


/* Sidebar
================================================== */

.sidebar .widget-title {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  margin: 0 0 30px;
  padding-left: 15px;
  text-transform: uppercase;
  border-left: 3px solid $color-primary;
}



/* Widget common */

.sidebar .widget {
  margin-bottom: 40px;
}

.sidebar-left .widget {
  margin-right: 20px;
}

.sidebar-right .widget {
  margin-left: 20px;
}

.sidebar .widget.box {
  padding: 25px;
}

.widget.box.solid {
  background: #f2f2f2;
}

.widget.box.red {
  background: #ec483b;
  color: #fff;
}

.widget.box.red .widget-title {
  color: #fff;
}

.widget ul li {
  line-height: 30px;
}

.sidebar .widget ul li a {
  color: #303030;
}

.sidebar .widget ul li a:hover {
  color: $color-primary;
}

.sidebar .widget ul li i {
  margin-right: 5px;
}

.sidebar .btn {
  font-weight: 700;
  font-size: 12px;
  margin-top: 15px;
  padding: 10px 25px;
}


/* Sidebar nav */

.sidebar ul.nav-tabs {
  border: 0;
}

.sidebar ul.nav-tabs li {
  width: 100%;
  a {
    color: #303030;
    border-radius: 0;
    padding: 15px 0;
    padding-left: 0;
    font-weight: 400;
    border-bottom: 1px solid #ddd;
    display: block;
    transition: 400ms;
  }
}

.sidebar ul.nav-tabs li.active a,
.sidebar ul.nav-tabs li:hover a {
  color: $color-primary;
}

.sidebar ul.nav-tabs li {
  color: #303030;
  line-height: normal;
}

.sidebar ul.nav-tabs li:last-child a {
  border-bottom: 0;
}


/* Service menu */

.sidebar ul.service-menu {
  li {
    width: 100%;
    a {
      background-color: #f4f4f4;
      padding: 15px 20px;
      border: 0;
      margin-bottom: 8px;
      position: relative;
      transition: 400ms;
      display: block;
    }
    &.active a,
    &:hover a {
      background: $color-primary;
      color: #fff;
      cursor: pointer;
    }
  }
}


/* Recent News */
.widget.recent-posts .widget-title {
  margin-bottom: 35px;
}

.widget.recent-posts {
  ul li {
    border-bottom: 1px solid #dadada;
    padding-bottom: 15px;
    margin-bottom: 17px;
    &:last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .posts-thumb img {
    margin-right: 15px;
    width: 90px;
    height: 70px;
  }
  .post-info .entry-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    a {
      color: #303030;
      display: inline-block;
      &:hover {
        color: $color-primary;
      }
    }
  }
  .post-date {
    font-weight: 400;
    color: #999;
    text-transform: capitalize;
  }
}


/* Widget tags */
.widget-tags ul>li {
  float: left;
  margin: 3px;
}

.sidebar .widget-tags ul>li a {
  border: 1px solid #dadada;
  color: #303030;
  display: block;
  font-size: 14px;
  padding: 3px 15px;
  transition: all 0.3s ease 0s;
}

.sidebar .widget-tags ul>li a:hover {
  background: $color-primary;
  color: #fff;
  border: 1px solid transparent;
}


/* Error page
================================================== */

.error-page .error-code h2 {
  display: block;
  font-size: 200px;
  line-height: 200px;
  color: #303030;
  margin-bottom: 20px;
}

.error-page .error-body .btn {
  margin-top: 30px;
  font-weight: 700;
}